* {
  box-sizing: border-box;
}

html {
  color-scheme: dark;
  background-color: #111;
}

html,
body {
  overflow: hidden;
}

html,
body,
#__next,
main {
  width: 100%;
  height: 100%;
  margin: 0px;
}

.header {
  max-width: 500px;
  padding-inline: 2rem;
}

.header > img {
  display: block;
  margin: auto;
  max-width: 100%;
}

.header > h2 {
  font-family: 'TWK Everett', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 144%;
  text-align: center;
  color: rgba(255, 255, 255, 0.6);
}

footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 1.5rem 2rem;
  text-align: center;
  color: rgba(255, 255, 255, 0.6);
  background-color: var(--lk-bg);
  border-top: 1px solid rgba(255, 255, 255, 0.15);
}

footer a,
h2 a {
  color: #ff6352;
  text-decoration-color: #a33529;
  text-underline-offset: 0.125em;
}

footer a:hover,
h2 a {
  text-decoration-color: #ff6352;
}

h2 a {
  text-decoration: none;
}

/* .lk-control-bar > .lk-button-group:nth-child(2) {
  display: none;
} */


.message-container {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
  color: #111;
}

.message-container.user {
  justify-content: flex-start;
}

.message-container.assistant {
  /* justify-content: flex-end; */
  flex-direction: row-reverse;
}

.message-bubble {
  background-color: #e6f7ff;
  padding: 10px;
  border-radius: 10px;
  max-width: 70%;
  word-break: break-word;
  margin-left: 10px;
}

.message-container.assistant .message-bubble {
  margin-left: 0;
  margin-right: 10px;
}

.zh-CN .lk-toast-connection-state::after{
  content: "123"
}